import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Hashids from 'hashids/cjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import FeedSessionCard from '../FeedSessionCard';
import CardListSkeleton from '../CardList/skeleton';
import { requestCigarSessions as requestCigarSessionsAction } from '../../pages/SessionEditor/actions';
import { CigarStore } from '../../stores';
import { config } from '../../settings';

const hashids = new Hashids('', 12);

function CigarSessions(props) {
  const [sessions, setSessions] = useState([]);
  const [selectedVitola, setSelectedVitola] = useState(null);

  const allSessions = CigarStore.useState((s) => s.sessions);

  // TODO Allow user to filter down to just their sessions, -just their friends- (done), -or all- (done)
  useEffect(() => {
    const { match: { params }, onLoad } = props;
    let { cigarId } = params;
    if (cigarId.indexOf('?') !== -1) {
      cigarId = cigarId.split('?')[0];
    }
    cigarId = cigarId.replace(/[\W_]+/g, '');
    if (props.currentUserOnly) {
      console.log(`Getting user sessions for cigar: ${hashids.decode(cigarId)}`);
      axios.get(`${config.apiEndPoint}/users/${props.auth.user.id}/cigars/${hashids.decode(cigarId)}/sessions`).then((response) => {
        setSessions(response.data);
      });
    } else if (props.friendsOnly) {
      console.log(`Getting friend sessions for cigar: ${hashids.decode(cigarId)}`);
      axios.get(`${config.apiEndPoint}/users/${props.auth.user.id}/cigars/${hashids.decode(cigarId)}/sessions?friends_only=true`).then((response) => {
        setSessions(response.data);
      });
    } else {
      console.log(`Getting sessions for cigar: ${hashids.decode(cigarId)}`);
      props.requestCigarSessions(hashids.decode(cigarId)[0], 1);
    }
  }, [props.match.params.cigarId]);

  // useEffect(() => {
  //   setSessions(props.sessions);
  //   if (typeof props.onSessionsLoaded === 'function') {
  //     props.onSessionsLoaded(props.sessions);
  //   }
  // }, [props.sessions]);

  useEffect(() => {
    if (!props.friendsOnly && !props.currentUserOnly) {
      const hashId = props.match.params.cigarId.split('?')[0];
      const cigarId = hashids.decode(hashId.replace(/[\W_]+/g, ''))[0];
      if (cigarId) {
        const cigarSessions = allSessions[cigarId];
        if (cigarSessions) {
          setSessions(cigarSessions);
          if (typeof props.onSessionsLoaded === 'function') {
            props.onSessionsLoaded(cigarSessions);
          }
        }
      }
    }
  }, [allSessions, props.match.params.cigarId]);

  useEffect(() => {
    setSelectedVitola(props.selectedVitola);
  }, [props.selectedVitola]);

  const filteredSessions = selectedVitola ? sessions.filter((session) => session.vitola && session.vitola.id === selectedVitola.id) : sessions;
  if (filteredSessions.length) {
    return (
      <div>
        <InfiniteScroll
          dataLength={filteredSessions.length}
          next={() => {}} // TODO Need to get next page
          hasMore={false} // TODO Get from reducer
          loader={<CardListSkeleton />}
        >
          {filteredSessions.map((session) => (
            <FeedSessionCard key={session.id} session={session} />
          ))}
        </InfiniteScroll>
      </div>
    );
  }
  return (
    <div style={{ margin: 20, textAlign: 'center' }}>
      {!selectedVitola && <span>Looks like this cigar does not have any smoke sessions yet!</span>}
      {selectedVitola && <span>There are no sessions for this vitola. Try selecting &quot;All&quot; from the filters.</span>}
    </div>
  );
}

// function mapStateToProps(state, props) {
//   const { match: { params } } = props;
//   let { cigarId } = params;
//   if (cigarId.indexOf('?') !== -1) {
//     cigarId = cigarId.split('?')[0];
//   }
//   return {
//     sessions: selectAllCigarSmokeSessions(hashids.decode(cigarId)[0], 1),
//   };
// }

function mapStateToProps(state) {
  return {
    // FIXME These are both now in stores/index.js UserStore and SettingsStore
    auth: state.get('auth').toJS(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestCigarSessions: (cigarId, page, callback) => dispatch(requestCigarSessionsAction(cigarId, page, callback)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CigarSessions));
