import React, { useEffect } from 'react';

const DynamicAd = ({ zoneId, attr }) => {
  useEffect(() => {
    console.log('Loading ad for zone ' + zoneId);
    if (window._ASO) {
      window._ASO.loadAd(`aso-zone-${zoneId}`, zoneId, true, { // HTML ID, Zone ID, async, params
        // attr: {
        //   tags:['a', 'b'], // FIXME Can these be used to make the ads more dynamic? Like ['mellow-medium', 'nicaragua', 'etc']
        //   keywords: ['cars'] // FIXME Can these be used to make the ads more dynamic? Like ['arturo fuente', 'diesel', 'etc']
        // },
        // FIXME These don't seem to be getting called - the docs say to use strings pointing to the function names but that didn't seem to work either
        onload: (params) => {
          console.log('Ad loaded:', params);
        },
        onempty: (idzone) => {
          console.log('No ads for zone #'+idzone);
        },
      });
    }
  }, []);

  return (
    <div style={{ minHeight: 2, width: '100%' }}>
      <ins id={`aso-zone-${zoneId}`} className="aso-zone" data-zone={zoneId} data-onload="onloadCallback" data-onempty="onemptyCallback" />
    </div>
  );
};

export default DynamicAd;
