import React from 'react';
import ModalDialog from '../ModalDialog';

const EditorDiscardConfirmation = {
  show: ({ title, message, onClick }) => {
    ModalDialog.show({
      title: title || 'Discard Post',
      message: message || 'Are you sure you want to discard this post?',
      buttons: [{
        label: 'No, Cancel',
        onClick: () => {
          ModalDialog.close();
        },
      }, {
        label: 'Yes, Discard',
        style: { color: 'rgb(239, 81, 100)' },
        color: 'danger',
        onClick: () => {
          if (typeof onClick === 'function') {
            onClick();
          }
          ModalDialog.close();
        },
      }],
      onClose: () => {
        ModalDialog.close();
      },
    });
  },
};

export default EditorDiscardConfirmation;
