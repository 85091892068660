import React from 'react';
import ReactDOM from 'react-dom';
import { isMobile, isIOS } from 'mobile-device-detect';
import Drawer from '@material-ui/core/Drawer';
import { List, ListItem, ListItemText, Snackbar } from '@material-ui/core';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import ActionSheet from '../ActionSheet';
import { language, messages } from '../../utils/localeUtils';
import Icon from '../Icon';

function BottomSheet(props) {
  if (isIOS) {
    return (
      <div style={{ zIndex: 10000 }}>
        <ActionSheet
          title={props.title}
          message={props.message}
          show={props.open}
          menus={props.items}
          onRequestClose={() => {
            if (props.toggle) {
              props.toggle();
            } else {
              BottomSheet.close();
            }
          }}
          cancelText={messages[language]?.cancel || 'Cancel'}
        />
      </div>
    );
  }
  if (isMobile) {
    return (
      <div>
        <Drawer
          anchor="bottom"
          open={props.open}
          onClick={props.toggle || BottomSheet.close}
          onRequestClose={props.toggle || BottomSheet.close}
        >
          <div
            role="presentation"
            onClick={props.toggle || BottomSheet.close}
            onKeyDown={props.toggle || BottomSheet.close}
          >
            {props.title && (
              <div
                style={{
                  textAlign: 'center',
                  padding: 10,
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                {props.title}
              </div>
            )}
            {props.message && (
              <div
                style={{
                  textAlign: 'center',
                  padding: 10,
                  fontSize: 12,
                }}
              >
                {props.message}
              </div>
            )}
            <List>
              {/* FIXME Render a cancel button? */}
              {props.items.map((item) => (
                <ListItem onClick={item.onClick}>
                  {item.icon && typeof item.icon === 'string' && (
                    <Icon name={item.icon} style={{ marginRight: 12 }} />
                  )}
                  {item.icon && typeof item.icon !== 'string' && item.icon}
                  <ListItemText primary={item.content} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </div>
    );
  }
  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle || BottomSheet.close}
      style={{ maxWidth: 320 }}
      fade
    >
      <div className="modal-header" style={{ padding: 12 }}>
        {props.title && <h3>{props.title}</h3>}
        <Button className="close" color="" onClick={props.toggle || BottomSheet.close}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody style={{ padding: 4 }}>
        <List>
          {props.items.map((item) => (
            <ListItem
              style={{ cursor: 'pointer' }}
              onClick={() => {
                item.onClick && item.onClick();
                (props.toggle && props.toggle()) || BottomSheet.close();
              }}
            >
              {item.icon && typeof item.icon === 'string' && (
                <Icon name={item.icon} style={{ marginRight: 12 }} />
              )}
              {item.icon && typeof item.icon !== 'string' && item.icon}
              <ListItemText primary={item.content} />
            </ListItem>
          ))}
        </List>
      </ModalBody>
      <ModalFooter style={{ padding: 12 }}>
        <Button color="secondary" onClick={props.toggle || BottomSheet.close}>{messages[language]?.close || 'Close'}</Button>
      </ModalFooter>
    </Modal>
  );
}

BottomSheet.show = (props) => {
  let targetId = 'react-bottom-sheet';
  if (props && props.id) {
    targetId = `react-bottom-sheet-${props.id}`;
  }
  let divTarget = document.getElementById(targetId);
  if (divTarget) {
    // Rerender - the mounted ModalDialog
    ReactDOM.render(<BottomSheet open="debounce" {...props} />, divTarget);
  } else {
    divTarget = document.createElement('div');
    if (props.id) {
      divTarget.id = `react-bottom-sheet-${props.id}`;
    } else {
      divTarget.id = 'react-bottom-sheet';
    }
    document.body.appendChild(divTarget);
    ReactDOM.render(<BottomSheet open="debounce" {...props} />, divTarget);
  }
};

BottomSheet.close = (props) => {
  let targetId = 'react-bottom-sheet';
  if (props && props.id) {
    targetId = `react-bottom-sheet-${props.id}`;
  }
  const target = document.getElementById(targetId);
  if (target) {
    // FIXME Get this close animation working on iOS
    target.classList.remove('react-actionsheet-show');
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(target);
      if (target.parentNode) {
        target.parentNode.removeChild(target);
      }
    }, 300);
  }
};

export default BottomSheet;
