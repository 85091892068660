import React, { Component } from 'react';
import Alert from '@material-ui/lab/Alert';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import './style.scss';

class BarcodeScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      hasPermissions: false,
      videoInputs: [],
      lastSelectedIndex: parseInt(localStorage.getItem('boxpressd-selected-camera') || 0),
      selectedVideoInput: null, // FIXME This needs set immediately - store it in ls?
    };
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      // alert(JSON.stringify(devices));
      console.log(JSON.stringify(devices));
      this.updateSelectedVideoInput(devices);
    });
  }

  updateSelectedVideoInput = (devices) => {
    // INFO This is the one that works - what distinguishes it?
    const index = this.state.lastSelectedIndex;
    const inputs = this.state.videoInputs;
    if (inputs.length === 0 && Array.isArray(devices)) {
      devices.forEach((device) => {
        // FIXME Try to throw out the front facing ones - right now, the Alert below shows the wrong label since all have at least 2 (front and back)
        if (device.kind === 'videoinput') {
          inputs.push(device);
        }
      });
    }
    console.log(`Using videoInput: ${JSON.stringify(inputs[index])}`);
    this.setState((prevState) => {
      const cameraIndex = (prevState.lastSelectedIndex + 1) % (inputs.length + 1);
      localStorage.setItem('boxpressd-selected-camera', `${cameraIndex}`);
      return {
        videoInputs: inputs,
        lastSelectedIndex: cameraIndex,
        selectedVideoInput: inputs[index],
      };
    });
  }

  openScanner = async () => {
    document.body.classList.add('qrscanner');
    this.stopScanner();
  }

  stopScanner = () => {
    document.body.classList.remove('qrscanner');
  };

  render() {
    const { loading, hasPermissions } = this.state;
    if (!loading && !hasPermissions) {
      return (
        <div>
          {/* TODO Maybe a "no camera" icon/image? - how do we handle it if they said no already? */}
          <div style={{ margin: 16 }}>Scanning barcodes requires the camera permission. Open system settings to allow it.</div>
        </div>
      );
    }
    return (
      <div className="barcode-container" style={{ background: '#000000' }} onClick={this.updateSelectedVideoInput}>
        <BarcodeScannerComponent
          width="100%"
          height="100%"
          videoConstraints={{
            width: 1280,
            height: 720,
            facingMode: { exact: 'environment' },
            // focusMode: "continuous", // TODO Does this need to be set? Or is it set by default?
            // FIXME Don't include deviceId for qr codes? Seems to work fine without it, no need to complicate things
            deviceId: this.state.selectedVideoInput ? this.state.selectedVideoInput.deviceId : null,
          }}
          onUpdate={(err, result) => {
            if (result) {
              if (this.props.qrcode && result.format === 11) {
                this.props.onDetected(result.text);
              }
              if (!this.props.qrcode && result.format !== 11) {
                this.props.onDetected(result.text);
              }
            }
          }}
        />
        <div className={`barcode-guide ${this.props.qrcode ? 'qr-code' : ''}`} />
        <div className="barcode-help">
          {/* FIXME Right now - if the phone has multiple cameras, it never uses the near focus one - how to force it to? */}
          {!this.props.qrcode && this.state.videoInputs.length < 2 && <Alert severity="info">If your barcode is blurry, place it on a flat surface</Alert>}
          {!this.props.qrcode && this.state.videoInputs.length > 1 && <Alert severity="info" onClick={this.updateSelectedVideoInput}>If your barcode is blurry, tap to focus</Alert>}
        </div>
      </div>
    );
  }
}

export default BarcodeScanner;
