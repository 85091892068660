import React from 'react';
import './style.scss';

const strengthString = (value) => {
  switch (value) {
    case 1:
      return 'Mellow';
    case 2:
      return 'Mellow-Medium';
    case 3:
      return 'Medium';
    case 4:
      return 'Medium-Full';
    case 5:
      return 'Full';
    default:
      return 'Unknown';
  }
};

const strengthPercent = (value) => {
  return value ? 100 - ((value - 1) * 25) : 100;
};

export default function CigarStrengthGauge(props) {
  return (
    <div
      style={{
        height: 50,
        textAlign: 'center',
        paddingTop: 10,
      }}
    >
      <div className="strength-bar" style={{ marginTop: -10 }}>
        <p>{strengthString(props.value)}</p>
        <span style={{ width: `${strengthPercent(props.value)}%` }} />
      </div>
    </div>
  );
}
