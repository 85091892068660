import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import Cropper from 'react-cropper';
import { Button as MaterialButton } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import Icon from '../Icon';
import ModalView from '../ModalView';
import './style.scss';
import { uploadToS3 } from '../../utils/imageUtils';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

export const maxResolution = 3000000;
export const resizedImage = (height, width) => {
  let x = width;
  let y = height;
  const ratio = height / width;

  // let h = height;
  // let w = width;
  // y / x = h / w;
  // y = (h / w) * x;
  // let ratio = h / w;
  // y = ratio * x;
  // y * x = maxResolution;
  // (ratio * x) * x = maxResolution;
  // x^2 = maxResolution / ratio;
  // x = Math.sqrt(maxResolution / ratio);
  // y = maxResolution / x;

  if ((width * height) > maxResolution) {
    x = Math.sqrt(maxResolution / ratio);
    y = maxResolution / x;
  }
  return { height: y, width: x };
};

export default function MediaCropper(props) {
  const cropper = useRef();

  const getCroppedCanvas = (width, height) => cropper.current.getCroppedCanvas({ width, height });

  const mergeImages = (file) => {
    for (let i = 0; i < props.imageFiles.length; i++) {
      if (props.imageFiles[i].uuid === file.uuid) {
        // TODO Show loading indicator? Set `props.loading(true)`?
        props.imageFiles[i] = file;
      }
    }
  };

  const onSave = (canvas) => {
    if (typeof props.onSave === 'function') {
      mergeImages({
        ...props.file,
        src: canvas.toDataURL(),
      });
      props.onSave(canvas, [...props.imageFiles]);
    }
  };

  const crop = () => {
    if (typeof cropper.current.getCroppedCanvas === 'function') {
      // console.log(cropper.current.getCroppedCanvas());
      const canvas = cropper.current.getCroppedCanvas();
      let { width, height } = canvas;
      const resized = resizedImage(height, width);
      const croppedCanvas = getCroppedCanvas(Math.floor(resized.width), Math.floor(resized.height));
      onSave(croppedCanvas);
      if (props.upload) {
        croppedCanvas.toBlob((blob) => {
          const base64 = canvas.toDataURL();
          const extension = base64 ? base64.split(';')[0].split('/')[1] : blob.name.split('.').pop();
          const uuid = props.file.uuid || uuidv4();
          const filename = `${props.filenameTemplate().replace('uuid', uuid)}.${extension}`;
          if (typeof props.onUploading === 'function') {
            props.onUploading(filename);
          }
          uploadToS3(blob, props.uploadFolder, filename, uuid).then((res) => {
            console.log('Uploaded to media server!');
            console.log(res);
            const file = {
              ...res.data,
              uuid,
              original_file: base64, // TODO Rename to original_src?
              src: res.data.media_url,
              type: 'image',
            };
            console.log(file);
            if (typeof props.onUploaded === 'function') {
              // console.log('Merging files...');
              mergeImages(file); // FIXME The code keeps stopping here for some reason
              // console.log('Calling props.onUploaded...');
              props.onUploaded(filename, [...props.imageFiles]);
            }
          }).catch((err) => {
            console.error(err);
          });
        }/* , 'image/png' */);
      }
    }
  };

  const renderCropper = () => (
    <Cropper
      ref={cropper}
      src={props.src} // FIXME Should be props.file.original_file (original_src?) || props.file.src
      // FIXME This is a start to BXPR-241 but it doesn't properly set the thumbnail viewport (for some reason, the x, y, height, and width values don't seem correct)
      // ready={(event) => {
      //   console.log('Cropper ready...');
      //   console.log(event);
      //   if (props.thumbnailAspectRatio) {
      //     console.log('Appending viewport...');
      //     console.log(jQuery('body').find('.cropper-crop-box'));
      //     const imageMock = jQuery('.cropper-view-box');
      //     const cropBox = jQuery('body').find('.cropper-crop-box');
      //     cropBox.prepend('<div class="cropper-viewport">' + imageMock.html() + '</div>');
      //     cropBox.css('background-color', 'rgba(0,0,0,0.25)');
      //     imageMock.find('img').css('display', 'none');
      //   }
      // }}
      // crop={(event) => {
      //   if (props.thumbnailAspectRatio) {
      //     console.log('Changed crop area:');
      //     console.log(event);
      //     console.log(event.detail);
      //
      //     // FIXME I think the problem is that these are based on the cropped image dimensions, not the crop-box (handles) div dimensions
      //     const { height, width } = event.detail;
      //
      //     const aspectHeight = height < width ? height : (width / props.thumbnailAspectRatio);
      //     const aspectWidth = width < height ? width : (height / props.thumbnailAspectRatio);
      //
      //     const viewport = jQuery('.cropper-viewport');
      //     viewport.css('height', aspectHeight);
      //     viewport.css('width', aspectWidth);
      //     // viewport.css('transform', `translateX(${event.detail.x}px) translateY(${event.detail.y}px)`);
      //     // viewport.css('top', height - (aspectHeight / 2));
      //     // viewport.css('left', width - (aspectWidth / 2));
      //
      //     const imagePreview = viewport.find('img');
      //     // TODO Need to calculate based on thumbnailAspectRatio or apply additional top and left css
      //     imagePreview.css('transform', `translateX(${-event.detail.x / 2}px) translateY(${-event.detail.y / 2}px)`);
      //     // imagePreview.css('top', -(height - (aspectHeight / 2)));
      //     // imagePreview.css('left', -(width - (aspectWidth / 2)));
      //   }
      // }}
      // cropmove={(event) => {
      //   if (props.thumbnailAspectRatio) {
      //     console.log('Moved:');
      //     console.log(event);
      //   }
      // }}
      style={{ height: '100%', width: '100%', maxHeight: '100vh', maxWidth: '100vw' }}
      className={props.circle ? 'cropper-circle-preview' : ''}
      // autoCropArea={1} // Inset from 0-1 (0-100% - default is 80%)
      // Cropper.js options
      viewMode={1}
      dragMode="none"
      aspectRatio={props.aspectRatio} // TODO Use state and app bar to allow user to change this between common types and freeform and pass in from props
      guides // FIXME Add prop to allow this to be hidden
    />
  );

  if (isMobile) {
    return (
      <ModalView
        open={props.open}
        showFrom="bottom"
        onClick={props.toggle}
        onClose={props.onClose}
        rightButtons={[
          <MaterialButton aria-label="Save Image" onClick={crop}>Next</MaterialButton>,
        ]}
      >
        {renderCropper()}
        <AppBar position="fixed" color="white" style={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <IconButton
              aria-label="rotate counter clockwise"
              edge="end"
              color="inherit"
              style={{ maxWidth: 50 }}
              onClick={() => cropper.current.rotate(-90)}
            >
              <Icon name="rotate-ccw" style={{ height: 22, width: 22 }} />
            </IconButton>
            <Box style={{ flexGrow: 1 }} />
            {/* FIXME Only if allowed - profile and cover photos have locked aspect ratio */}
            {/*<IconButton*/}
            {/*  aria-label="change aspect ratio"*/}
            {/*  edge="end"*/}
            {/*  color="inherit"*/}
            {/*  style={{ maxWidth: 50 }}*/}
            {/*  onClick={() => {*/}
            {/*    // TODO Show tooltip or similar menu to let user pick aspect ratio*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Icon name="crop" style={{ height: 22, width: 22 }} />*/}
            {/*</IconButton>*/}
            {/*<Box style={{ flexGrow: 1 }} />*/}
            <IconButton
              aria-label="rotate clockwise"
              edge="end"
              color="inherit"
              style={{ maxWidth: 50 }}
              onClick={() => cropper.current.rotate(90)}
            >
              <Icon name="rotate-cw" style={{ height: 22, width: 22 }} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </ModalView>
    );
  }

  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
      style={{ maxWidth: 600 }}
      fade
    >
      <div className="modal-header">
        <Button className="close" color="" onClick={props.onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        {renderCropper()}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.onClose}>Close</Button>
        <Button color="primary" onClick={crop}>Save</Button>
      </ModalFooter>
    </Modal>
  );
}

MediaCropper.propTypes = {
  src: PropTypes.any.isRequired, // FIXME Start phasing this out and use file, which should include the src prop
  file: PropTypes.object, // .isRequired, TODO Should be required once src is phased out
  imageFiles: PropTypes.array,
  upload: PropTypes.bool,
  uploadFolder: PropTypes.string,
  filenameTemplate: PropTypes.func,
  aspectRatio: PropTypes.number,
  circle: PropTypes.bool,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  onClose: PropTypes.func,
  onUploading: PropTypes.func,
  onUploaded: PropTypes.func,
};

MediaCropper.defaultProps = {
  imageFiles: [],
  upload: false,
  uploadFolder: null,
  filenameTemplate: () => {},
  circle: false,
  open: false,
  aspectRatio: null,
  toggle: () => {},
  onClose: () => {},
};
