/**
 * External Dependencies
 */
import Loadable from 'react-loadable';
import React from 'react';
import { Spinner } from 'reactstrap';

export default function AsyncComponent(props) {
  // eslint-disable-next-line @wordpress/no-unused-vars-before-return
  const LoadableComponent = Loadable({
    loader: props.component,
    loading: () => (
      <Spinner
        color="secondary"
        type="grow"
      >
                    Loading...
      </Spinner>
    ),
  });

  return (
    <LoadableComponent />
  );
}
