import React, { useEffect, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import axios from 'axios';
import MobileSearchBar from '../MobileSearchBar';
import Drink from '../../models/Drink';
import PropTypes from 'prop-types';
import LocationSearch from '../location-search';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

function DrinkPairingSearch(props) {
  const [query, setQuery] = useState(null);
  const [drinks, setDrinks] = useState([]);

  useEffect(() => {
    updateResults();
  }, [query]);

  const updateResults = () => {
    if (query) {
      axios.get(`${config.apiEndPoint}/cigars/pairings/search`, {
        params: {
          q: query,
          limit: 30,
        },
      }).then((response) => {
        setDrinks(response.data);
      }).catch((err) => {
        console.log(err);
      });
    } // TODO Popular drink pairings to start?
  };

  return (
    <div>
      <MobileSearchBar
        placeholder="Search drink pairings…"
        onQueryChange={(newQuery) => setQuery(newQuery)}
      />
      <List style={{ ...props.listStyle }}>
        {drinks.map((drink) => (
          <ListItem
            alignItems="flex-start"
            onClick={() => props.onSelect(drink)}
          >
            <ListItemAvatar>
              <Avatar alt={drink.name} src={Drink.getImage(drink)}>{drink.name.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={drink.name}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

DrinkPairingSearch.propTypes = {
  listStyle: PropTypes.object,
};

DrinkPairingSearch.defaultProps = {
  listStyle: {},
};

export default DrinkPairingSearch;
