import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Icon from '../Icon';
import { isIOS } from 'mobile-device-detect';
import safeAreaInsets from 'safe-area-insets';

const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
const insetTop = safeAreaInsets.top;

const useStyles = makeStyles(() => ({
  navbarTitle: {

  },
  vineMenuItem: {
    cursor: 'pointer',
  },
  icon: {
    transition: 'all .08s linear',
    '-moz-transition': 'all .08s linear',
    '-webkit-transition': 'all .08s linear',
  },
  iconOpen: {
    transform: 'rotate(180deg)',
    '-webkit-transform': 'rotate(180deg)',
    '-ms-transform': 'rotate(180deg)',
  },
  vineMenuOverlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'none',
    // zIndex: -1,
    position: 'fixed',
    alignItems: 'center',
    touchAction: 'none',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  overlayOpen: {
    display: 'flex',
    opacity: 1,
    zIndex: 1002,
  },
  vineMenu: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    position: 'fixed',
    top: '-100%',
    left: 0,
    width: '100%',
    background: '#ffffff',
    textAlign: 'center',
    // display: 'none',
    zIndex: 1002,
    paddingTop: (window.Capacitor.getPlatform() === 'ios' || (isIOS && isInStandaloneMode)) ? insetTop + 15 : 15,
    boxShadow: '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    transition: 'all .4s ease-in-out',
    '-moz-transition': 'all .4s ease-in-out',
    '-webkit-transition': 'all .4s ease-in-out',
    '& li': {
      cursor: 'pointer',
      padding: '14px 0',
    },
  },
  menuOpen: {
    display: 'block',
    top: 56,
  },
}));

// FIXME The CSS animations for the dropdown menu aren't working
export default function VineMenu(props) {
  const classes = useStyles();

  const toggleDropdown = (event) => {
    if (typeof props.onClick === 'function') {
      props.onClick(event);
    }
  };

  return (
    <div className={classes.navbarTitle} onClick={toggleDropdown} style={{ textAlign: 'center' }}>
      <span className={classes.vineMenuItem}>
        {props.title}
        {/* FIXME Get this to add .open to the class dynamically and remove the change in icon below */}
        <Icon
          className={`${classes.icon} ${props.open ? classes.iconOpen : ''}`}
          // name={props.open ? 'chevron-up' : 'chevron-down'}
          name="chevron-down"
          style={{ height: 18, width: 18, marginTop: -2, marginLeft: 6 }}
        />
      </span>
    </div>
  );
}

export function VineMenuDropDown(props) {
  const classes = useStyles();
  const [activeHumidor, setActiveHumidor] = useState(null);

  const changeHumidor = (humidor) => {
    setActiveHumidor(humidor);
    if (typeof props.onSelectHumidor === 'function') {
      props.onSelectHumidor(humidor);
    }
  };

  return (
    <div>
      <div
        className={`${classes.vineMenuOverlay} ${props.open ? classes.overlayOpen : ''}`}
        onClick={props.onClick}
      />
      <ul className={`vine-menu ${classes.vineMenu} ${props.open ? classes.menuOpen : ''}`}>
        {props.humidors && props.humidors.map((humidor) => <li onClick={() => changeHumidor(humidor)}>{humidor.name}</li>)}
        {props.showManager && (
          <li
            onClick={() => {
              if (typeof props.onShowManager === 'function') {
                props.onShowManager();
              }
            }}
          >
            {'Manage humidors...'}
          </li>
        )}
      </ul>
    </div>
  );
}
