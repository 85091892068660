import React, { useEffect, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import axios from 'axios';
import MobileSearchBar from '../MobileSearchBar';
import Cigar from '../../models/Cigar';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

export default function CigarSearch(props) {
  const [query, setQuery] = useState(null);
  const [cigars, setCigars] = useState([]);

  useEffect(() => {
    updateResults();
  }, [query]);

  const updateResults = () => {
    if (query) {
      axios.get(`${config.apiEndPoint}/cigars/search`, {
        params: {
          q: query,
          limit: 30,
        },
      }).then((response) => {
        setCigars(response.data);
      }).catch((err) => {
        console.log(err);
      });
    } else {
      // TODO Get from recent sessions?
    }
  };

  return (
    <div>
      <MobileSearchBar
        placeholder="Search cigars…"
        onQueryChange={(newQuery) => setQuery(newQuery)}
      />
      <List>
        {cigars.map((cigar) => (
          <ListItem
            alignItems="flex-start"
            onClick={() => props.onSelect(cigar)}
          >
            <ListItemAvatar>
              <Avatar
                className="avatar-contained"
                alt={cigar.full_name}
                src={Cigar.getBandImage(cigar)}
              >
                {cigar.name ? cigar.name.charAt(0) : cigar.full_name.charAt(0)}
              </Avatar>
            </ListItemAvatar>
            {/*<ListItemText*/}
            {/*  primary={<span style={{ fontSize: '0.8rem' }}>{cigar.brand}</span>}*/}
            {/*  secondary={<span style={{ fontSize: '1.1rem' }}>{cigar.name || ''}</span>}*/}
            {/*/>*/}
            <ListItemText
              primary={<span style={{ fontSize: '1.2rem' }}>{cigar.full_name}</span>}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
