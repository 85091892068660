import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { language, messages } from '../../utils/localeUtils';
import './style.scss';

let legendColors = ['#221100', '#342801', '#4c3f14', '#66572b', '#817143', '#9d8b5c', '#b9a675', '#d6c290', '#f4dfac'].reverse();

function CigarCompositionChart(props) {
  const [data, setData] = React.useState(null);
  const [options, setOptions] = React.useState(null);
  // FIXME Way to share these across the two components in this file?
  const [wrapperLabels, setWrapperLabels] = React.useState(['Unknown']);
  const [wrapperColors, setWrapperColors] = React.useState([]);
  const [binderLabels, setBinderLabels] = React.useState(['Unknown']);
  const [binderColors, setBinderColors] = React.useState([]);
  const [fillerLabels, setFillerLabels] = React.useState(['Unknown']);
  const [fillerColors, setFillerColors] = React.useState([]);

  const { cigar } = props;

  React.useEffect(() => {
    console.log();
    let count = 0;

    const labels = [];
    const temp = [];

    const wrapperLabels = cigar.wrapper ? cigar.wrapper.split(', ') : ['Unknown'];
    const wrapperColors = [];
    const binderLabels = cigar.binder ? cigar.binder.split(', ') : ['Unknown'];
    const binderColors = [];
    const fillerLabels = cigar.filler ? cigar.filler.split(', ') : ['Unknown'];
    const fillerColors = [];

    console.log(wrapperLabels);
    console.log(binderLabels);
    console.log(fillerLabels);

    for (let i = 0; i < wrapperLabels.length; i++) {
      if (typeof temp[wrapperLabels[i]] === 'undefined') {
        if (wrapperLabels[i] === 'Unknown') {
          wrapperColors.push('#e0e0e0');
        } else {
          temp[wrapperLabels[i]] = legendColors[count];
          wrapperColors.push(legendColors[count]);
          count++;
        }
      }
    }

    for (let j = 0; j < binderLabels.length; j++) {
      if (typeof temp[binderLabels[j]] === 'undefined') {
        if (binderLabels[j] === 'Unknown') {
          binderColors.push('#e0e0e0');
        } else {
          temp[binderLabels[j]] = legendColors[count];
          binderColors.push(legendColors[count]);
          count++;
        }
      } else {
        // Find the correct color
        binderColors.push(temp[binderLabels[j]]);
      }
    }

    for (let k = 0; k < fillerLabels.length; k++) {
      if (typeof temp[fillerLabels[k]] === 'undefined') {
        if (fillerLabels[k] === 'Unknown') {
          fillerColors.push('#e0e0e0');
        } else {
          temp[fillerLabels[k]] = legendColors[count];
          fillerColors.push(legendColors[count]);
          count++;
        }
      } else {
        // Find the correct color
        fillerColors.push(temp[fillerLabels[k]]);
      }
    }

    for (let key in temp) {
      labels.push(key);
    }

    console.log(labels);

    const wrapperValues = new Array(wrapperLabels.length).fill(1);
    const binderValues = new Array(binderLabels.length).fill(1);
    const fillerValues = new Array(fillerLabels.length).fill(1);

    setData({
      labels,
      datasets: [{
        label: messages[language]?.wrapper || 'Wrapper',
        data: wrapperValues,
        backgroundColor: wrapperColors,
        labels: wrapperLabels,
      },
      {
        label: messages[language]?.binder || 'Binder',
        data: binderValues,
        backgroundColor: binderColors,
        labels: binderLabels,
      },
      {
        label: messages[language]?.filler || 'Filler',
        data: fillerValues,
        backgroundColor: fillerColors,
        labels: fillerLabels,
      }],
    });

    setOptions({
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 0,
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let dataset = data.datasets[tooltipItem.datasetIndex];
            let index = tooltipItem.index;
            return dataset.labels[index];
          },
        },
      },
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    });

    console.log('Data:');
    console.log(data);
  }, [cigar]);

  return (
    <div style={{ width: '100%', minHeight: 200 }}>
      <Doughnut data={data} options={options} style={{ margin: 'auto' }} />
    </div>
  );
}

export function Legend(props) {
  const [wrapperLabels, setWrapperLabels] = React.useState(['Unknown']);
  const [binderLabels, setBinderLabels] = React.useState(['Unknown']);
  const [fillerLabels, setFillerLabels] = React.useState(['Unknown']);
  const [selectedColors, setSelectedColors] = React.useState({ 'Unknown': '#e0e0e0' });

  const { cigar } = props;

  React.useEffect(() => {
    if (cigar.wrapper) {
      setWrapperLabels(cigar.wrapper.split(', '));
    }
    if (cigar.binder) {
      setBinderLabels(cigar.binder.split(', '));
    }
    if (cigar.filler) {
      setFillerLabels(cigar.filler.split(', '));
    }

    console.log(wrapperLabels);
    console.log(binderLabels);
    console.log(fillerLabels);
  }, [cigar]);

  React.useEffect(() => {
    console.log();
    let count = 0;

    const tempSelectedColors = { ...selectedColors };

    for (let i = 0; i < wrapperLabels.length; i++) {
      if (typeof tempSelectedColors[wrapperLabels[i]] === 'undefined') {
        if (wrapperLabels[i] !== 'Unknown') {
          tempSelectedColors[wrapperLabels[i]] = legendColors[count];
          count++;
        }
      }
    }

    for (let j = 0; j < binderLabels.length; j++) {
      if (typeof tempSelectedColors[binderLabels[j]] === 'undefined') {
        if (binderLabels[j] !== 'Unknown') {
          tempSelectedColors[binderLabels[j]] = legendColors[count];
          count++;
        }
      }
    }

    for (let k = 0; k < fillerLabels.length; k++) {
      if (typeof tempSelectedColors[fillerLabels[k]] === 'undefined') {
        if (fillerLabels[k] !== 'Unknown') {
          tempSelectedColors[fillerLabels[k]] = legendColors[count];
          count++;
        }
      }
    }

    setSelectedColors(tempSelectedColors);
  }, [wrapperLabels, binderLabels, fillerLabels]);

  return (
    <div className="legend">
      <h4 style={{ marginTop: 5, marginBottom: 5 }}>{messages[language]?.wrapper || 'Wrapper'}</h4>
      <ul>
        { wrapperLabels.map((label) => (
          <li>
            <div className="legend-swatch" style={{ backgroundColor: selectedColors[label] }} />
            {label}
          </li>
        )) }
      </ul>
      <h4 style={{ marginTop: 5, marginBottom: 5 }}>{messages[language]?.binder || 'Binder'}</h4>
      <ul>
        { binderLabels.map((label) => (
          <li>
            <div className="legend-swatch" style={{ backgroundColor: selectedColors[label] }} />
            {label}
          </li>
        )) }
      </ul>
      <h4 style={{ marginTop: 5, marginBottom: 5 }}>{messages[language]?.filler || 'Filler'}</h4>
      <ul>
        { fillerLabels.map((label) => (
          <li>
            <div className="legend-swatch" style={{ backgroundColor: selectedColors[label] }} />
            {label}
          </li>
        )) }
      </ul>
    </div>
  );
}

CigarCompositionChart.Legend = Legend;

export default CigarCompositionChart;
